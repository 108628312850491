import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '../components/layouts'

class Illustrations extends Component {
  render() {
    const illustrations = this.props.data.allContentfulImageCollection.edges[0].node.images.map(
      (img) => (
        <div key={img.id} className="f f-col a-center">
          <GatsbyImage
            image={img.gatsbyImageData}
            alt={img.title}
            className="image__img--print fadeInUp"
          />
        </div>
      ),
    )

    return (
      <Layout>
        <div className="grid-images">{illustrations}</div>
      </Layout>
    )
  }
}

export default Illustrations

export const query = graphql`
  query CollectionOfPrintsQuery {
    allContentfulImageCollection(
      filter: { category: { title: { eq: "Prints" } } }
    ) {
      edges {
        node {
          id
          images {
            id
            title
            gatsbyImageData(placeholder: NONE)
          }
        }
      }
    }
  }
`
